import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.css';
import users from '../server_backend/users.json';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const user = users.find(user => user.email === email && user.password === password);
    if (user) {
      localStorage.setItem('isAuthenticated', 'true');
      localStorage.setItem('userEmail', user.email);
      navigate('/auth-inicial');
    } else {
      console.log('Invalid credentials'); // Log invalid login attempt
      alert('Email ou senha incorretos');
    }
  };

  return (
    <div className="App">
      <img src="/front-inicial.png" alt="Logo" className="App-logo" />
      {!isDrawerOpen && (
        <button className="drawer-toggle" onClick={handleDrawerToggle}>
          <span className="menu-icon">&#9776;</span>
        </button>
      )}
      <div className={`drawer ${isDrawerOpen ? 'open' : ''}`}>
        <button className="close-btn" onClick={handleDrawerToggle}>×</button>
        <ul>
          <li><a href="/">Página inicial</a></li>
          <li><a href="/login">Login</a></li>                    
          <li><a href="/termos-de-uso">Termos de Uso</a></li>
        </ul>
      </div>
      <div className="app-container">
        <img src="/background.png" alt="Background" className="background-image" />
        <div className="Login-box">
          <h1>Login</h1>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Email:</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                autoComplete="email"
              />
            </div>
            <div className="form-group">
              <label>Senha:</label>
              <div className="password-input">
                <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  autoComplete="current-password"
                />
                <button
                  type="button"
                  className="toggle-password"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <img src={showPassword ? "/olho_fechado.png" : "/olho_aberto.png"} alt="Visualizar senha" />
                </button>
              </div>
            </div>
            <button type="submit" className="login-button">Entrar</button>
            {/* <a href="/reset-password" className="forgot-password-link">Esqueci minha senha</a> */}
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
