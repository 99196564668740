import React, { useState } from 'react';
import './index.css';

function App() {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  return (
    <div className="App">
      <img src="/front-inicial.png" alt="Logo" className="App-logo" />
      <button className="drawer-toggle" onClick={handleDrawerToggle}>
        <span className="menu-icon">&#9776;</span>
      </button>
      <div className={`drawer ${isDrawerOpen ? 'open' : ''}`}>
        <button className="close-btn" onClick={handleDrawerToggle}>×</button>
        <ul>
          <li><a href="/">Página inicial</a></li>
          <li><a href="/login">Login</a></li>          
          <li><a href="/termos-de-uso">Termos de Uso</a></li>
        </ul>
      </div>
      <div className="app-container">
        <img src="/background.png" alt="Background" className="background-image" />
        <div className="content">
          <h1>Do seu jeito, agora vai!</h1>
          <h2><font color="#935522">Sobre Nós</font></h2>
          <p>A <span className="highlight"><font color='#dd6906'>RAN</font>GOO</span> nasceu com o propósito de revolucionar o mercado de alimentação fitness, oferecendo uma alimentação personalizada e de alta qualidade. Nosso objetivo é proporcionar praticidade e saúde para quem busca uma alimentação equilibrada e nutritiva.</p>
          <h2><font color="#dd6906">Nossa Missão</font></h2>
          <p>Ajuda você a comer melhor, de forma personalizada, para alcançar seus objetivos, sem complicação.</p>
          <h2><font color='#bf3c04'>Nossos Valores</font></h2>
          <p>Tornar a nutrição personalizada <span className="highlight">acessível a todos</span>, promovendo autonomia e reduzindo o desperdício de alimentos.</p>
          <h2><font color='#5f6726'>Serviços</font></h2>
          <p>Na <span className="highlight"><font color='#dd6906'>RAN</font>GOO</span>, entendemos que cada pessoa tem suas próprias necessidades nutricionais. Por isso, oferecemos um serviço personalizado para ajudar você a atingir seus objetivos de saúde e bem-estar.</p>
          <h2>Contato</h2>
          <p>Email: contato@rangoo.app.br</p>
          <p>Instagram: <a href="https://www.instagram.com">Instagram</a></p>
        </div>
      </div>
    </div>
  );
}

export default App;
