import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './home';
import Login from './login';
import Inicial from './auth-inicial';
import ResetPassword from "./reset-password";
import UpdateOrder from './update-order';
import SearchOrder from './search-order';
import TermoApp from './termo-app';

function App() {
  const isAuthenticated = localStorage.getItem('isAuthenticated');

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/auth-inicial" element={isAuthenticated ? <Inicial /> : <Navigate to="/" />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/update-order" element={isAuthenticated ? <UpdateOrder /> : <Navigate to="/" />} />   
          <Route path="/search-order" element={isAuthenticated ? <SearchOrder /> : <Navigate to="/" />} />          
          <Route path="/termos-de-uso" element={<TermoApp />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
