import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.css';

function Dashboard() {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('isAuthenticated');
    navigate('/login');
  };

  return (
    <div className="App">
      <img src="/front-inicial.png" alt="Logo" className="App-logo" />
      {!isDrawerOpen && (
        <button className="drawer-toggle" onClick={handleDrawerToggle}>
          <span className="menu-icon">&#9776;</span>
        </button>
      )}
      <div className={`drawer ${isDrawerOpen ? 'open' : ''}`}>
        <button className="close-btn" onClick={handleDrawerToggle}>×</button>
        <ul>
          <li><a href="/auth-inicial">Home</a></li>
          <li><a href="/update-order">Atualizar Status</a></li>
          <li><a href="/search-order">Informações de Pedidos</a></li>
          <li><a href="/" onClick={handleLogout}>Logout</a></li>
        </ul>
      </div>
      <div className="app-container">
        <img src="/background.png" alt="Background" className="background-image" />
        <div className="Dashboard-box">
          <h1>Área Logada</h1>
          <button className="action-button" onClick={() => navigate('/update-order')}>Atualizar Status do Pedido</button>
          <button className="action-button" onClick={() => navigate('/search-order')}>Buscar Informações de Pedidos</button>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
